import {DateTime} from 'luxon';

export default {
    convertTime(time, timeframe) {
        let t = DateTime.fromMillis(time)
        let ft = '';
        if(timeframe === 'day'){
            ft = t.setZone("America/New_York").startOf('day').toFormat("yyyy-MM-dd")
        } else {
            let local = t.toLocal();    
            
            ft =  (local.ts + (local.o * 60 * 1000)) /1000;
        }
        return ft;
       
    }
}