<template>
    <div ref="chart_container">
        <!-- <v-card  elevation="0" class="mt-1"> -->
        
        <div v-show="isLoaded && !isResizing" ref="chart"></div>
        
        <div v-if="!isLoaded" class="pa-10">
            <p class="text-body-2 text-center">Retreiving Data</p>
            <v-progress-linear
                color="accent"
                indeterminate
                rounded
                height="6"
            ></v-progress-linear>
        
        </div>
    
    <!-- </v-card> -->
    </div>
</template>


<script>

import { createChart, CrosshairMode } from 'lightweight-charts';

  import { mapGetters, mapMutations } from 'vuex';
  import { EventBus } from '../../modules/EventBus';
  import {DateTime} from 'luxon';

    import Utils from '../../modules/Utils';

// import { EventBus } from '../../../../trader_web/src/modules/EventBus';
    export default {
        name: 'ChartIndex',
        data: () => ({
            // enabled_indicators:['emas', 'hloc', 'smas' ],
            isResizing: false,
            tick_data: [],
            trade_data: {},

            chart: null,
            chart_options: {
                chart: {
                   marginRight: 50,
                   animation: false,
                //    width: 100, 
                   
                }, 
                 plotOptions: {
                     states: {
                        // hover: {
                        //     enabled: false
                        // },
                        inactive: {
                            opacity: 1,
                        }
                    }, 
                    series: {
                        animation: false,
                        states: {
                        // hover: {
                        //     enabled: false
                        // },
                            inactive: {
                                opacity: 1,
                            }
                        }, 
                        // enableMouseTracking: false,
                        // label: false
                    }, 
                    candlestick: {
                        allowPointSelect: false,
                        color: '#e74c3c', 
                        upColor: '#2ecc71',
                        pointPadding: 0,
                        groupPadding: .2,
                        
                        stickyTracking: false
                    },
                    
                    
                }, 
                rightPriceScale: {
                    visible: true,
                },
                leftPriceScale: {
                    visible: true,
                },
                tooltip: {
                    shared: true, 
                    stickyTracking: false,
                    enabled: true
                        
                },
                credits: {
                    enabled: false
                }, 
                exporting: {
                    enabled: false
                }, 
                scrollbar: { enabled: false },
                rangeSelector: {
                    enabled: false
                },
                navigator: {
                    enabled: false
                },
                title: {
                    text: ''
                },
                yAxis: {
                    crosshair: {
                        label: {
                            enabled: true
                        }
                    },
                    labels:{
                        align: 'left'
                    }, 
                    plotLines: [],
                },
                xAxis: {
                    
                    plotLines: [],
                    labels:{}
                },
                series: []
            },
            time_increments: {
                minute: 1000 * 60 * 5,
                hour: 1000 * 60 * 60,
                day: 1000 * 60 * 60 * 24
            },
            candlestickSeries: null,
            volumeSeries: null,
            maximaConsoidationLineSeries: null,
            minimaConsoidationLineSeries: null,
            consolidationFormationBaselineSeries: null,
            consolidationFormationlineSeries: null,
            sma50LineSeries: null,
            sma100LineSeries: null,
            sma200LineSeries: null,
            ema3LineSeries: null,
            ema8LineSeries: null,
            ema21LineSeries: null,
            vwapLineSeries: null,
            openLineSeries: null,
            closeLineSeries:  null,
            highLineSeries: null,
            lowLineSeries: null,
            last_time: null,
            chartClicked: false,
            last_price: {},
            chart_low: 0,
            chart_high: 0,

        }),

        components:{},
        props: ['lines', 'selectedTimeframe', 'chartData', 'dailyChart', 'intradayTrendsChart', 'intradayChart', 'activeTicker', 'isLoaded', 'id', 'chartDate'], 

         mounted(){
            this.timeframe = this.selectedTimeframe;
            this.ticker = this.activeTicker;
            this.date = this.chartDate;

            if(this.chartData){
                this.createChart();
            }


        }, 
        async created(){ 
            EventBus.$on('resize', this.resizeChart);
            EventBus.$on('price_update', this.updatePrices)
        },
        async destroyed(){ 
            EventBus.$off('resize', this.resizeChart);
            EventBus.$off('price_update', this.updatePrices)
        },
        computed: {
            ...mapGetters({
            }), 

            formatted_daily_data(){
                if(!this.chart) return [];
                let ticks = JSON.parse(JSON.stringify(this.dailyChart.prices)); 

                if(this.lines.indexOf('ha_candles') >=0 ){
                    ticks = this.makeHACandles(ticks); 
                }
              
                let mapped_ticks =  ticks.map(td => {
                    let date = {
                        open: td.open,
                        close: td.close,
                        high: td.high,
                        low:  td.low,
                        time: Utils.convertTime(td.time, 'day'),
                    }
                    return date;
            
                });
                return mapped_ticks;

            }, 
            daily_range(){
                
                this.chart_high = Math.max(...this.formated_data.map(t => t.high));
                this.chart_low = Math.min(...this.formated_data.map(t => t.low));
                let atr = this.dailyChart.prices[0].atr;
                return {
                    high: this.chart_high + ( atr / 2),
                    low: this.chart_low - (atr / 2)
                } 
            }, 
            // formated_spy_data(){
            //     if(!this.intradayChart || !this.intradayChart.spy_ticks) {
            //          this.last_time = null;
            //          return [];
            //     };

            //     let spy_ticks = JSON.parse(JSON.stringify(this.intradayChart.spy_ticks)); 
    
            //     let mapped_ticks =  spy_ticks.map(td => {
                
            //         let date = {
            //             value: td.close,
            //             time: Utils.convertTime(td.time, this.selectedTimeframe),
            //         }
            //         return date;
            
            //     });
            //     //this.last_time = mapped_ticks[mapped_ticks.length - 1].time;
            //     return mapped_ticks;
            // }, 
            formated_data(){
                if(!this.chartData?.prices) {
                     this.last_time = null;
                     return [];
                };
   
                let ticks = JSON.parse(JSON.stringify(this.chartData.prices)); 
    
                if(this.lines.indexOf('ha_candles') >=0 ){
                    ticks = this.makeHACandles(ticks); 
                }
                
                let mapped_ticks =  ticks.map(td => {
                    let color = null;
                    color = td.is_key_bar && td.open < td.close ? '#aee6c5' : color;
                    color = td.is_key_bar && td.open > td.close ? '#ffcdc7' : color;
                    
                    let date = {
                        open: td.open,
                        close: td.close,
                        high: td.high,
                        low:  td.low,
                        color: color,
                        time: Utils.convertTime(td.time, this.selectedTimeframe)
                    }
                    return date;
            
                });
                
                // this.last_time = mapped_ticks[mapped_ticks.length - 1].time;
                return mapped_ticks;
            },
            formated_volume_data(){
                 if(!this.chartData?.prices.length || !this.chart) return [];

                let ticks = JSON.parse(JSON.stringify(this.chartData.prices)); 

                let mapped_ticks =  ticks.map(td => {
                    let date = {
                        value: td.volume,
                        time: Utils.convertTime(td.time, this.selectedTimeframe),
                    }
                    return date;
            
                });
                
                return mapped_ticks;
            }
        }, 
        methods: {
            resizeChart(){ 
                if(this.chart){
                    this.chart.resize(this.$refs.chart_container.offsetWidth, this.$refs.chart_container.offsetWidth / 1.8);
                }
            }, 
            resetData(){
                this.candlestickSeries = null;
                this.volumeSeries = null;
                this.maximaConsoidationLineSeries = null;
                this.minimaConsoidationLineSeries = null;
                this.consolidationFormationBaselineSeries = null;
                this.consolidationFormationlineSeries = null;
                this.sma50LineSeries = null;
                this.sma100LineSeries = null;
                this.sma200LineSeries = null;
                this.ema3LineSeries = null;
                this.ema8LineSeries = null;
                this.ema21LineSeries = null;
                this.vwapLineSeries = null;
                this.openLineSeries = null;
                this.closeLineSeries =  null;
                this.highLineSeries = null;
                this.lowLineSeries = null;
                this.last_time = null;
                try{
                    this.chart.remove();
                } catch(err){
                  //   console.log(err)
                }
            }, 
            whiteSpaceSeries(){
                let data = this.formated_data;
                
                if(!data.length) return [];
                 
                let vals = [];
                for(let i = 0; i < data.length; i++){
                    vals.push({
                        time: data[i].time
                    })
                }

                let starting_time = data[0].time;
                for(let i = data.length; i < 78; i++){
                    vals.push({
                        time: starting_time + (i * this.time_increments[this.selectedTimeframe])
                    })
                }

                const lineSeries = this.chart.addLineSeries({ 
                    color: '#FFFF',
                    priceLineVisible: false,
                    lineWidth: 1,
                    lastValueVisible: false,
                    visible: false
                });

                lineSeries.setData(vals);



            }, 


            makeHACandles(data){ 
                let haCandles = data.map((d, i, arr) => {
                    d.close = (d.open + d.close + d.high + d.close) * .25
                    if(i >= 1){
                        d.open = (arr[i-1].open + arr[i-1].close) * .5
                    } else { 
                        d.open = d.open; 
                    }
                    d.high = Math.max(d.open, d.close, d.high)
                    d.low = Math.min(d.open, d.close, d.low)
                    return d;
                });

                return haCandles;
            },
            reflowChart(){  
            }, 
            setResizing(isResizing){
            
            }, 
           shouldSetDetails(){
                if(this.isLoaded){
                    this.createChart();
                }
            },
            createChart(){
    
                this.resetData();
            
                this.chart = createChart(this.$refs.chart, { 
                    width: this.$refs.chart_container.offsetWidth, 
                    height: this.$refs.chart_container.offsetWidth / 1.8, 
                    click: (params) => {
                        console.log('clicked', params)
                    },
                    onClick: (params) => {
                        console.log('clicked', params)
                    },
                    MouseEventHandler: (params) => {
                        console.log('clicked', params)
                    },
                    timeScale: {
                        fixLeftEdge: true,
                        fixRightEdge: true,
                        timeVisible: true,
                        // tickMarkFormatter: (time) => {
                        //     console.log("time", time)
                            
                        //     let datetime = DateTime.fromSql(time);
                            
                        //     // if(this.selectedTimeframe === 'day'){
                        //     //     return datetime.toLocaleString();
                        //     // } else {
                        //     //     return datetime.toLocaleString(DateTime.TIME_SIMPLE);
                        //     // }
                        // } 
                    },
                    axisDoubleClickReset: true,
                    crosshair: {
                        mode: CrosshairMode.Normal,
                        horzLine: {
                            visible: true,
                            labelVisible: true,
                        },
                        vertLine: {
                            visible: true,
                            labelVisible: true,
                        },
                    }, 
                    grid: {
                        vertLines: {
                            visible: false, 
                        },
                        horzLines: {
                            color: '#f0f0f0', 
                        },
                    }
                });

                if(!this.formated_data.length) return;


                this.last_price = this.formated_data[this.formated_data.length -1];

               // let day_start = DateTime.fromMillis(this.last_price.time)


                this.chart.subscribeClick(this.handleClick);


                this.chart.subscribeCrosshairMove(param => {
                    if(!param.time) return;
                    const date = DateTime.fromFormat(`${param.time.year}-${param.time.month}-${param.time.day}`, 'yyyy-M-d', { zone: "America/New_York" }).plus({days: 1});
                  //  console.log(`The time is: ${date.ts}`);
                });
                  
                this.chart.timeScale().subscribeVisibleTimeRangeChange(this.timeChange);
                
                try{
                    this.addCandleSticksSeries();
                } catch(err){
                    console.log("candle stick Err", err)
                }
                
                
                try{
                    this.whiteSpaceSeries(); 
                } catch(err){
                    console.log("whitespace err", err)
                }    
                try{
                    this.daySeparators(); 
                } catch(err){
                    console.log("daySeparators err", err)
                }   
                try{
                   
                    this.addVolumeSeries(); 
                } catch(err){
                    console.log("addVolumeSeries err", err)
                }
                
                


                // try{
                //     this.addMinimasMaximas();
                // } catch(err){
                //     console.log("Minima Maxima Err", err)
                // }

                  // return;

                try {
           //         this.addSPYLine();
                } catch(err){
                    console.log("add Spy Line Err", err)
                }
              
                this.chart.timeScale().fitContent();
                
     
                if(this.lines.indexOf('compression_boxes') >= 0){
                    try {
                        this.addCompressionBoxes();
                    } catch(err){
                        console.log(err); 
                    }
                }



            //     if(this.lines.indexOf('consolidation') >= 0){
            //         try {
            //             this.addConsolidationLines();
            //         } catch(err){
            //             console.log(err); 
            //         }
            //     }

            if(this.lines.indexOf('trendlines') >= 0){
                try {
                    this.addTrendLines();
                } catch(err){
                    console.log(err); 
                }
            }
            console.log("this.lines", this.lines); 
               if(this.lines.indexOf('horizontal_support_daily') >= 0){
                   try {
                        this.addHorizontalSupport();
                    } catch(err){
                        console.log(err); 
                    }
                 }
            if(this.lines.indexOf('intraday_horizontal_support') >= 0 ){
                try {
                    this.addIntradayHorizontalSupport();
                } catch(err){
                    console.log(err); 
                }
            }

            if(this.lines.indexOf('intraday_trendlines') >= 0 ){
                try {
                    this.addIntradayTrendLines();
                } catch(err){
                    console.log(err); 
                }
            }

            if(this.lines.indexOf('smas') >= 0){
                try {
                   this.addSMAs();
                } catch(err){
                    console.log(err); 
                }
            }

            // if(this.lines.indexOf('hloc') >= 0){
            //     try {
            //         this.addHLOC();
            //     } catch(err){
            //         console.log(err); 
            //     }
            // }

                if(this.lines.indexOf('emas') >= 0){
                    try {
                        this.addEMAs();
                    } catch(err){
                        console.log(err); 
                    }
                }




                if(this.selectedTimeframe == 'day'){

                    this.chart.timeScale().setVisibleRange({
                        from: this.formated_data[this.formated_data.length - 79].time ,
                        to: this.formated_data[this.formated_data.length - 1].time
                    })
                } else {
                    
                    this.chart.timeScale().setVisibleRange({
                        from: this.formated_data[this.formated_data.length - 79].time ,
                        to: this.formated_data[this.formated_data.length - 1].time
                    })
                }

               

                
                return;
        
            },
            handleClick(params){
                
                this.$emit('clicked', params.time)

                
                // if(this.chartClicked){
                //     this.$emit('clicked', params.time)
                // }
                // this.chartClicked = true;
                
                // setTimeout(() => {
                //     console.log("this.chartClicked", this.chartClicked)
                //     this.chartClicked = false;
                // }, 500);
                // console.log("this.chartClicked", this.chartClicked)
            }, 
            timeChange(evt){
                this.$emit('timechange', evt)
            }, 


            updatePrices(data){

                // if(this.activeTicker !== data.ticker) return;
                // const coeff = 1000 * 60 * 5; 
                // data.time =  Math.floor(data.time / coeff) * coeff
                
                // if(data.time != this.last_price.time && this.activeTicker != 'SPY' && this.selectedTimeframe == 'minute'){
                //     console.log('reloading')
                //     this.$store.dispatch('dashboardStore/setTicker',  {
                //         ticker: this.activeTicker,
                //         silent: true
                //     });
                //     this.$store.dispatch('dashboardStore/setTicker',  {
                //         ticker: 'SPY',
                //         silent: true
                //     });
                //     EventBus.$emit('reload_stock');
                    
                // } 
             
                // let { open, close, high, low, time} = data;
                // this.candlestickSeries.update({
                //     open: +open, 
                //     close: +close, 
                //     high: +high, 
                //     low: +low, 
                //     time: +time
                // });
            }, 

            addSPYLine(){
                const spySeries = this.chart.addLineSeries(
                    { 
                        priceScaleId: 'left',
                        color: '#a0a0a0',
                        priceLineVisible: true,
                        lineWidth: 1,
                        // lastValueVisible: false,
                    }
                );
                
                spySeries.setData(this.formated_spy_data);

            }, 
            daySeparators(){
                if(this.selectedTimeframe === 'day') return;

                let config = { 
                    color: '#e2e2e2',
                    priceLineVisible: false,
                    lineWidth: 1,
                    lastValueVisible: false,
                }
                let start = this.chartData.prices[0];
    
                let end = this.chartData.prices.slice(-1)[0];
                let st = DateTime.fromMillis(start.time,  { zone: "America/New_York" });
                let et = DateTime.fromMillis(end.time,  { zone: "America/New_York" });

                let time = st.endOf('day');
                let data = []
                
                let triggered = 0;
                while (time.valueOf() < et.valueOf()){
                    data.push({ time: time.valueOf()/1000, value: 1 }); 
                    time = time.plus({days: 1})
                }

                let verticalSeries = this.chart.addHistogramSeries({
                    priceScaleId: 'vertical',
                    color: 'rgba(200, 200, 200, 0.25)',
                        scaleMargins: { top: 0, bottom: 0 }      
                    })
                    verticalSeries.setData(data);

            },
            addCandleSticksSeries(update){
                
                if(update && this.candlestickSeries){

                    let last = this.formated_data.slice(-1);
                    last = last[0]
                   
                    this.candlestickSeries.update({
                        open: +last.open, 
                        close: +last.close, 
                        high: +last.high, 
                        low: +last.low, 
                        time: last.time
                    });

                   // this.update(this.candlestickSeries, this.formated_data, 'close');
                } else {   

                    let high = Math.max(...this.formated_data.slice(-79).map(o => o.high));
                    let low = Math.min(...this.formated_data.slice(-79).map(o => o.low));


                    console.log("highlow", high, low)

                    this.candlestickSeries = this.chart.addCandlestickSeries({
                        autoscaleInfoProvider: () => ({
                            priceRange: {
                                minValue: low,
                                maxValue: high,
                            },
                            // margins: {
                            //     above: 10,
                            //     below: 10,
                            // },
                        }),
                    });
                    
                    this.candlestickSeries.setData(this.formated_data);
                }
                this.candlestickSeries.priceScale().applyOptions({
                    autoScale: false, // disables auto scaling based on visible content
                    scaleMargins: {
                        top: 0.1,
                        bottom: 0.3,
                    },
                });
               
            }, 

            addVolumeSeries(update){

                // if(update && this.volumeSeries){

                //     let last = this.formated_volume_data.slice(-1);
                //     last = last[0];
                //     this.volumeSeries.update({
                //         time: last.time,
                //         value: last.value

                //     })

                //     // this.update(this.volumeSeries, this.formated_volume_data, 'value');
                // } else {

                    this.volumeSeries = this.chart.addHistogramSeries({
                        
                        color: '#eef2f3',
                        priceFormat: {
                            type: 'volume',
                        },
                        priceScaleId: '', // set as an overlay by setting a blank priceScaleId
                        // set the positioning of the volume series
                        scaleMargins: {
                            top: 0.7, // highest point of the series will be 70% away from the top
                            bottom: 0,
                        },
                    });
                
                     
                    this.volumeSeries.setData(this.formated_volume_data);
              
                // }
                
            }, 

            addCompressionBoxes(){
                
                if(!this.chartData.compression) return;
                
                 for(let i = 0; i < this.chartData.compression.length; i++){
                     let config = { 
                        color: '#e33d94',
                        priceLineVisible: false,
                        lineWidth: 2,
                        lastValueVisible: false,
                    }
                    const lineSeriesBottom = this.chart.addLineSeries(config);
                    const dataBottom = [
                        { value: this.chartData.compression[i].period_low,  time: Utils.convertTime(this.chartData.compression[i].starting_point.time, this.selectedTimeframe) }, 
                        { value: this.chartData.compression[i].period_low,  time: Utils.convertTime(this.chartData.compression[i].ending_point.time, this.selectedTimeframe)  }
                    ] 
                
                    lineSeriesBottom.setData(dataBottom);

                    const lineSeriesTop = this.chart.addLineSeries(config);

                    const dataTop = [
                        { value: this.chartData.compression[i].period_high,  time: Utils.convertTime(this.chartData.compression[i].starting_point.time, this.selectedTimeframe) }, 
                        { value: this.chartData.compression[i].period_high,  time: Utils.convertTime(this.chartData.compression[i].ending_point.time, this.selectedTimeframe) }
                    ] 

                    lineSeriesTop.setData(dataTop);


                    const lineSeriesLeft = this.chart.addLineSeries(config);
                    const dataLeft = [
                        { value: this.chartData.compression[i].period_low,  time: Utils.convertTime(this.chartData.compression[i].starting_point.time, this.selectedTimeframe)  }, 
                        { value: this.chartData.compression[i].period_high,  time: Utils.convertTime(this.chartData.compression[i].starting_point.time, this.selectedTimeframe)  }
                    ] 

                    lineSeriesLeft.setData(dataLeft);
                    
                    const lineSeriesRight = this.chart.addLineSeries(config);
                    const dataRight = [
                        { value: this.chartData.compression[i].period_low,  time: Utils.convertTime(this.chartData.compression[i].ending_point.time, this.selectedTimeframe) }, 
                        { value: this.chartData.compression[i].period_high,  time: Utils.convertTime(this.chartData.compression[i].ending_point.time, this.selectedTimeframe) }
                    ] 

                    lineSeriesRight.setData(dataRight);




                 
                 
                 }
            }, 
            addHorizontalSupport(){
                
                
                if(this.dailyChart?.trendlines?.horizontal){
                    
                    for(let i = 0; i < this.dailyChart.trendlines.horizontal.length; i++){
                        let tl = this.dailyChart.trendlines.horizontal[i];
                         

                        let start = this.selectedTimeframe === 'day' ? tl.Points[0] : {time: this.formated_data[0].time};
                        let end = this.selectedTimeframe === 'day'  ?tl.Points.slice(-1)[0] : {time: this.formated_data.slice(-1)[0].time};

                        let sft = this.selectedTimeframe == 'day' ? Utils.convertTime(start.time, this.selectedTimeframe) : start.time; 
                        let eft = this.selectedTimeframe == 'day' ? Utils.convertTime(end.time, this.selectedTimeframe) : end.time; 
 
                        const data = [
                            { value: +tl.price,  time: sft }, 
                            { value: +tl.price,  time: eft }
                        ]

                        let end_value = +tl.price;

                        if(end_value > this.daily_range.high || end_value < this.daily_range.low) continue;

                        const lineSeries = this.chart.addLineSeries({ 
                            color:  !!tl.Points[tl.Points.length - 1].is_broken ? '#130f40' : '#be2edd',
                            priceLineVisible: false,
                            lineWidth: 1,
                            lastValueVisible: false,
                        });
                        

                        console.log("ok", data)
                        lineSeries.setData(data)
                        
                    
                    }
                }   
            },

            addMinimasMaximas(){
                let markers = [];
                
                if(this.chartData?.trendlines?.maximas){
                    for(let i =0; i < this.chartData.trendlines.maximas.length; i++){
                        
                        markers.push(
                            { time: this.chartData.trendlines.maximas[i].time, position: 'aboveBar', color: '#f68410', shape: 'circle', text: '' }
                        );
                    }
                    
                
                }
                if(this.chartData?.trendlines?.minimas){    
                    for(let i =0; i < this.chartData.trendlines.minimas.length; i++){
                        markers.push(
                            { time: this.chartData.trendlines.minimas[i].time, position: 'belowBar', color: '#f68410', shape: 'circle', text: '' }
                        );
                    }
                }
                this.candlestickSeries.setMarkers(markers);
        
            }, 

            // addConsolidationLines(){
            //     if(this.intradayTrendsChart?.consolidation?.maximaConsoidationLines){
            //         for(let i =0; i < this.intradayTrendsChart.consolidation.maximaConsoidationLines.length; i++){
            //             let line = this.intradayTrendsChart.consolidation.maximaConsoidationLines[i]
            //             let maximaConsoidationLineSeries = this.chart.addLineSeries({ 
            //                color: this.intradayTrendsChart.consolidation.consolidationPattern === "Breakout" ? '#00a8ff': '#a0a0a0',
            //                priceLineVisible: false,
            //                lineWidth: 2,
            //                lastValueVisible: false,
            //            });
            //             const data = [
            //                 { value: line.start.price,   time: line.start.time }, 
            //                 { value: (line.m * this.formated_data.length) + line.b, time: this.formated_data[this.formated_data.length - 1].time }];

            //             maximaConsoidationLineSeries.setData(data);

            //         }
                    
            //     }
                
            //     if(this.intradayTrendsChart?.consolidation?.minimaConsoidationLine?.start){

            //         this.minimaConsoidationLineSeries = this.chart.addLineSeries({ 
            //             color: this.intradayTrendsChart.consolidation.consolidationPattern === "Breakdown" ? '#00a8ff': '#a0a0a0',
            //             priceLineVisible: false,
            //             lineWidth: 2,
            //             lastValueVisible: false,
            //         });
            //         const data = [
            //             { value: this.intradayTrendsChart.consolidation.minimaConsoidationLine.start.price,   time: this.intradayTrendsChart.consolidation.minimaConsoidationLine.start.time }, 
            //             { value: (this.intradayTrendsChart.consolidation.minimaConsoidationLine.m * this.formated_data.length) + this.intradayTrendsChart.consolidation.minimaConsoidationLine.b, time: this.formated_data[this.formated_data.length - 1].time }];

            //         this.minimaConsoidationLineSeries.setData(data);
            //     }  
                
                // if(this.intradayTrendsChart?.consolidation?.formation === "Wedge"){

                //     let range_data = this.formated_data.map(d => [ d[0], this.intradayTrendsChart.consolidation.minimaConsoidationLine.end.price, this.intradayTrendsChart.consolidation.maximaConsoidationLine.end.price ])


                //     this.consolidationFormationBaselineSeries = this.chart.addBaselineSeries({ 
                //         baseValue: { 
                //             type: 'price', 
                //             price: this.intradayTrendsChart.consolidation.minimaConsoidationLine.end.price 
                //         }, 
                //         lineWidth: 1,
                //         topLineColor: 'rgba( 0, 168, 255, 1)', 
                //         topFillColor1: 'rgba( 0, 168, 255, .25)',
                //         topFillColor2: 'rgba(0, 168, 255, .25)',
                //         bottomLineColor:  'rgba( 0, 168, 255, 1)' , 
                //         bottomFillColor1:  'rgba( 0, 168, 255, .25)',
                //         bottomFillColor2: 'rgba( 0, 168, 255, .25)' 
                //     });

                //     const data = [
                //         { 
                //             value: this.intradayTrendsChart.consolidation.maximaConsoidationLine.end.price, 
                //             time: this.formated_data[0].time 
                //         }, 
                //         { 
                //             value: this.intradayTrendsChart.consolidation.maximaConsoidationLine.end.price, 
                //             time:  this.formated_data[this.formated_data.length-1].time 
                //         }   
                //     ]
                //     this.consolidationFormationBaselineSeries.setData(data);


                //     this.consolidationFormationlineSeries = this.chart.addLineSeries({ 
                //             color: '#00a8ff',
                //             priceLineVisible: false,
                //             lineWidth: 1,
                //             lastValueVisible: false,
                //         });
                //         const lineSeriesData = [
                //             { 
                //                 value: this.intradayTrendsChart.consolidation.minimaConsoidationLine.end.price, 
                //                 time: this.formated_data[0].time 
                //             }, 
                //             { 
                //                 value: this.intradayTrendsChart.consolidation.minimaConsoidationLine.end.price, 
                //                 time:  this.formated_data[this.formated_data.length-1].time 
                //             }   
                //         ]
                //         this.consolidationFormationlineSeries.setData(lineSeriesData);
                // }



            // }, 


            addIntradayHorizontalSupport(){
                if(this.selectedTimeframe === 'day') return;

                if(this.chartData?.trendlines?.horizontal){
                    
                    for(let i = 0; i < this.chartData.trendlines.horizontal.length; i++){
                        let tl = this.chartData.trendlines.horizontal[i];
                         const lineSeries = this.chart.addLineSeries({ 
                            color:  !!tl.Points[tl.Points.length - 1].is_broken ? '#130f40' : '#be2edd',
                            priceLineVisible: false,
                            lineWidth: 1,
                            lastValueVisible: false,
                        });
                        
                            let start = tl.Points[0];
                            let end = tl.Points.slice(-1)[0];
                            
                            let sft = Utils.convertTime(start.time, this.selectedTimeframe)
                            let eft = Utils.convertTime(end.time, this.selectedTimeframe)
                        
                            const data = [
                                { value: +tl.price,  time: sft }, 
                                { value: +tl.price,  time: eft }
                            ]
                        
                            lineSeries.setData(data);

                    }
                }   



            }, 

            addIntradayTrendLines(){

                if(this.selectedTimeframe === 'day') return;

                if(this.selectedTimeframe == 'minute'){
                   
                    for(let i = 0; i < this.chartData.trendlines.maxima.length; i++){
                        let tl = this.chartData.trendlines.maxima[i];
                         const lineSeries = this.chart.addLineSeries({ 
                            color:  !!tl.Points[tl.Points.length - 1].is_broken ? '#130f40' : '#be2edd',
                            priceLineVisible: false,
                            lineWidth: 1,
                            lastValueVisible: false,
                        });
 
                        let start = tl.Points[0];
                        let end = tl.Points.slice(-1)[0];

                        let sft = Utils.convertTime(start.time, this.selectedTimeframe)
                        let eft = Utils.convertTime(end.time, this.selectedTimeframe)

                        const data = [
                            { value: +start.price,  time: sft }, 

                            { value: +end.price,  time: eft }
                        ]
                    
                        lineSeries.setData(data);
                    }

                    for(let i = 0; i < this.chartData.trendlines.minima.length; i++){
                        let tl = this.chartData.trendlines.minima[i];
                         const lineSeries = this.chart.addLineSeries({ 
                            color:  !!tl.Points[tl.Points.length - 1].is_broken ? '#130f40' : '#be2edd',
                            priceLineVisible: false,
                            lineWidth: 1,
                            lastValueVisible: false,
                        });
 
                        let start = tl.Points[0];
                        let end = tl.Points.slice(-1)[0];

                        let sft = Utils.convertTime(start.time, this.selectedTimeframe)
                        let eft = Utils.convertTime(end.time, this.selectedTimeframe)

                        const data = [
                            { value: +start.price,  time: sft }, 

                            { value: +end.price,  time: eft }
                        ]
                    
                        lineSeries.setData(data);
                    }

                }
            }, 
            addTrendLines(){
                let markers = [];
                console.log("adding trendlines")
                if(this.dailyChart?.trendlines?.maxima){
                    
                    for(let i = 0; i < this.dailyChart.trendlines.maxima.length; i++){
                        let tl = this.dailyChart.trendlines.maxima[i];
                         const lineSeries = this.chart.addLineSeries({ 
                            color:  !!tl.Points[tl.Points.length - 1].is_broken ? '#130f40' : '#be2edd',
                            priceLineVisible: false,
                            lineWidth: 1,
                            lastValueVisible: false,
                        });


                        if(this.selectedTimeframe === 'day'){
                            let start = tl.Points[0];
                            let end = tl.Points.slice(-1)[0];

                            let sft = Utils.convertTime(start.time, this.selectedTimeframe)
                            let eft = Utils.convertTime(end.time, this.selectedTimeframe)

                            const data = [
                                { value: +start.price,  time: sft }, 

                                { value: +end.price,  time: eft }
                            ]
                        
                            lineSeries.setData(data);
                        } else {
                            let hours24 = 1000 * 60 * 60 * 24; 
                            
                            let start = tl.Points[0];
                            let end = tl.Points.slice(-1)[0];

                            // if(tl.Points[tl.Points.length - 1].is_broken) continue; 
                            
                            if(end.time < this.chartData.prices[0].time) continue;
                            
                            let tl_start_index = this.dailyChart.prices.findIndex(p => p.time >= tl.first );
                            let first_point_index = this.dailyChart.prices.findIndex(p => p.time >= tl.Points[0].time && p.time >= this.chartData.prices[0].time );
                            let last_point_index = this.dailyChart.prices.length - 1 -  this.dailyChart.prices.toReversed().findIndex(p => p.time <= end.time );

                            
                            let first_day = this.dailyChart.prices[first_point_index];
                            let last_day = { ...this.dailyChart.prices[last_point_index] };

                            let startingPoint = {
                                time: tl.Points[0].time <  this.chartData.prices[1].time ? this.chartData.prices[0].time: first_day.time,
                                price:  +(tl.m * (first_point_index - tl_start_index - 1)) + +tl.b
                            }

                            
                            //    last_point_index++;
                            last_day.time += hours24
                            
                            let endingPoint = {
                                time:   last_day.time,
                                price:  +(tl.m * (last_point_index - tl_start_index )) + +tl.b
                            }

                            let sft = Utils.convertTime(startingPoint.time, this.selectedTimeframe)
                            let eft = Utils.convertTime(endingPoint.time, this.selectedTimeframe)
                            
                            const data = [
                                { value: +startingPoint.price,  time: sft }, 
                                { value: +endingPoint.price,  time: eft }
                            ]

                            lineSeries.setData(data);
                        }   
                    }
                }   

       
                if(this.dailyChart?.trendlines?.minima){
                    
                    for(let i = 0; i < this.dailyChart.trendlines.minima.length; i++){
                        let tl = this.dailyChart.trendlines.minima[i];
                         const lineSeries = this.chart.addLineSeries({ 
                            color:  !!tl.Points[tl.Points.length - 1].is_broken ? '#130f40' : '#be2edd',
                            priceLineVisible: false,
                            lineWidth: 1,
                            lastValueVisible: false,
                        });


                        if(this.selectedTimeframe === 'day'){
                            let start = tl.Points[0];
                            let end = tl.Points.slice(-1)[0];
                            
                    
                            let sft = Utils.convertTime(start.time, this.selectedTimeframe)
                            let eft = Utils.convertTime(end.time, this.selectedTimeframe)

                            const data = [
                                { value: +start.price,  time: sft }, 
                                { value: +end.price,  time: eft }
                            ]
            
                            lineSeries.setData(data);
                        } else {

                            let hours24 = 1000 * 60 * 60 * 24; 
                            
                            let start = tl.Points[0];
                            let end = tl.Points.slice(-1)[0];

                           
                            if(end.time < this.chartData.prices[0].time) continue;

                            let tl_start_index = this.dailyChart.prices.findIndex(p => p.time >= tl.first );
                            let first_point_index = this.dailyChart.prices.findIndex(p => p.time >= tl.Points[0].time && p.time >= this.chartData.prices[0].time );
                            let last_point_index = this.dailyChart.prices.length - 1 - this.dailyChart.prices.toReversed().findIndex(p => console.log("p.time", p.time) || p.time <= end.time );

                
                            let first_day = { ...this.dailyChart.prices[first_point_index] };
                            let last_day = { ...this.dailyChart.prices[last_point_index] };

                            let startingPoint = {
                                time: start.time <  this.chartData.prices[1].time ? this.chartData.prices[0].time: first_day.time,
                                price:  +(tl.m * (first_point_index - tl_start_index - 1)) + +tl.b
                            }

                          
                            // last_point_index++;
                            last_day.time += hours24
                            let endingPoint = {
                                time:   last_day.time,
                                price:  +(tl.m * (last_point_index - tl_start_index )) + +tl.b
                            }
                         

                            let sft = Utils.convertTime(startingPoint.time, this.selectedTimeframe)

                            
                            let eft = Utils.convertTime(endingPoint.time, this.selectedTimeframe)
                            
                            const data = [
                                { value: +startingPoint.price,  time: sft }, 
                                { value: +endingPoint.price,  time: eft }
                            ]

                            lineSeries.setData(data); 
                        }   
                    }
                }   


                
    

            }, 
            addSMAs(update){
                
                if(this.selectedTimeframe === 'day'){
                 
                    if(update) return;
                    this.sma50LineSeries = this.chart.addLineSeries({ 
                         autoscaleInfoProvider: () => null, 
                        color: '#f1c40f',
                        priceLineVisible: false,
                        lineWidth: 2,
                        lastValueVisible: false,
                    });

                    
                    let sma50 =  this.chartData.prices.map(td => {
                        let data = {
                            value: td.ma1,
                            time: Utils.convertTime(td.time, this.selectedTimeframe),
                        }
                        return data;
                    })
                
                    this.sma50LineSeries.setData(sma50);

                     this.sma100LineSeries = this.chart.addLineSeries({ 
                         autoscaleInfoProvider: () => null, 
                        color: '#e67e22',
                        priceLineVisible: false,
                        lineWidth: 2,
                        lastValueVisible: false,
                    });
                    let sma100 =  this.chartData.prices.map(td => {
                        let data = {
                            value: td.ma2,
                            time: Utils.convertTime(td.time, this.selectedTimeframe),
                        }
                        return data;
                    })

                    this.sma100LineSeries.setData(sma100);
                     this.sma200LineSeries = this.chart.addLineSeries({ 
                        autoscaleInfoProvider: () => null, 
                        color: '#e74c3c',
                        priceLineVisible: false,
                        lineWidth: 2,
                        lastValueVisible: false,
                    });

                    let sma200 =  this.chartData.prices.map(td => {
                        let data = {
                            value: td.ma3,
                            time: Utils.convertTime(td.time, this.selectedTimeframe),
                        }
                        return data;
                    })


                    this.sma200LineSeries.setData(sma200);


                } else {
                    
                    if(update && this.sma50LineSeries){    
                        
                        this.sma50LineSeries.update({ value: this.dailyChart.sma50[this.dailyChart.sma50.length - 1].value, time: this.formated_data[this.formated_data.length-1].time })
                        
                        
                    } else {

                        let start_value = this.chartData.prices[0].sma50;
                        let end_value = this.chartData.prices[this.chartData.prices.length - 1].sma50;

                     
                      //  if(end_value <= this.daily_range.high && end_value >= this.daily_range.low) {
                            this.sma50LineSeries = this.chart.addLineSeries({ 
                                 autoscaleInfoProvider: () => null, 
                                color: '#f1c40f',
                                priceLineVisible: false,
                                lineWidth: 2,
                                lastValueVisible: false,
                            });

                            const sma50data = [
                                { value: start_value , time: Utils.convertTime(this.chartData.prices[0].time, this.selectedTimeframe)}, 
                                { value: end_value, time:  Utils.convertTime(this.chartData.prices[this.chartData.prices.length - 1].time, this.selectedTimeframe) }];
                            
                            this.sma50LineSeries.setData(sma50data);
                       // };

                    }

                    if(update && this.sma100LineSeries){
                        this.sma100LineSeries.update({ value: this.dailyChart.sma100[this.dailyChart.sma100.length - 1].value, time: this.formated_data[this.formated_data.length-1].time })
                    } else {
                        let end_value = this.chartData.prices[this.chartData.prices.length - 1].sma100;
                        let start_value = this.chartData.prices[0].sma100;
                        
                        if(end_value <= this.daily_range.high && end_value >= this.daily_range.low) {
                            this.sma100LineSeries = this.chart.addLineSeries({ 
                                autoscaleInfoProvider: () => null, 
                                color: '#e67e22',
                                priceLineVisible: false,
                                lineWidth: 2,
                                lastValueVisible: false,
                            });

                             const sma100data = [
                                { value: start_value , time: Utils.convertTime(this.chartData.prices[0].time, this.selectedTimeframe)}, 
                                { value: end_value, time:  Utils.convertTime(this.chartData.prices[this.chartData.prices.length - 1].time, this.selectedTimeframe) }];
                            
                            this.sma100LineSeries.setData(sma100data);
                        };

                    }
                    
                    if(update && this.sma200LineSeries){
                         this.sma200LineSeries.update({ value: this.dailyChart.sma200[this.dailyChart.sma200.length - 1].value, time: this.formated_data[this.formated_data.length-1].time })
  
                    } else {
                        let end_value = this.chartData.prices[this.chartData.prices.length - 1].sma200;
                        let start_value = this.chartData.prices[0].sma200;
                        if(end_value <= this.daily_range.high && end_value >= this.daily_range.low) {
                            this.sma200LineSeries = this.chart.addLineSeries({ 
                                 autoscaleInfoProvider: () => null, 
                                color: '#e74c3c',
                                priceLineVisible: false,
                                lineWidth: 2,
                                lastValueVisible: false,
                            });

                            

                            const sma200data = [
                                { value: start_value , time: Utils.convertTime(this.chartData.prices[0].time, this.selectedTimeframe)}, 
                                { value: end_value, time:  Utils.convertTime(this.chartData.prices[this.chartData.prices.length - 1].time, this.selectedTimeframe) }];

                            this.sma200LineSeries.setData(sma200data);
                        };
                    
                    }
               
                }
        
            }, 
            addEMAs(update){

                if(this.selectedTimeframe !== 'minute') return;

                if(update && this.ema3LineSeries){
             //      this.update(this.ema3LineSeries, this.intradayChart.ema8, 'value');
                } else {

                    this.ema3LineSeries = this.chart.addLineSeries({ 
                        color: '#70a1ff',
                        priceLineVisible: false,
                        lineWidth: 2,
                        lastValueVisible: false,
                    });

                    
                    let ema3 =  this.chartData.prices.map(td => {
                        let data = {
                            value: td.ma1,
                            time: Utils.convertTime(td.time, this.selectedTimeframe),
                        }
                        return data;
                    })
                    
                    this.ema3LineSeries.setData(ema3);

                }

                if(update && this.ema8LineSeries){
            //       this.update(this.ema8LineSeries, this.intradayChart.ema8, 'value');
                } else {

                    this.ema8LineSeries = this.chart.addLineSeries({ 
                        color: '#3742fa',
                        priceLineVisible: false,
                        lineWidth: 2,
                        lastValueVisible: false,
                    });

                    
                    let ema8 =  this.chartData.prices.map(td => {
                        let data = {
                            value: td.ma2,
                            time: Utils.convertTime(td.time, this.selectedTimeframe),
                        }
                        return data;
                    })
                    
                    this.ema8LineSeries.setData(ema8);
                    
                }


                if(update && this.ema21LineSeries){
            //       this.update(this.ema8LineSeries, this.intradayChart.ema21, 'value');
                } else {

                    this.ema21LineSeries = this.chart.addLineSeries({ 
                        color: '#4857BE', 
                        priceLineVisible: false,
                        lineWidth: 2,
                        lastValueVisible: false,
                    });

                    
                    let ema21 =  this.chartData.prices.map(td => {
                        let data = {
                            value: td.ma3,
                            time: Utils.convertTime(td.time, this.selectedTimeframe),
                        }
                        return data;
                    })
                    
                    this.ema21LineSeries.setData(ema21);
                    
                }


                if(update && this.vwapLineSeries){
            //       this.update(this.ema8LineSeries, this.intradayChart.ema21, 'value');
                } else {

                    this.vwapLineSeries = this.chart.addLineSeries({ 
                        color: '#8e44ad',
                        priceLineVisible: false,
                        lineWidth: 2,
                        lastValueVisible: false,
                    });

                    
                    let vwap =  this.chartData.prices.filter(td => td.vwap).map(td => {
                        let data = {
                            value: td.vwap,
                            time: Utils.convertTime(td.time, this.selectedTimeframe),
                        }
                        return data;
                    })


                    
                    this.vwapLineSeries.setData(vwap);
                    
                }

            },

            addHLOC(update){

                if(this.selectedTimeframe !== 'minute') return;

                let last_day = this.dailyChart[this.dailyChart.length - 2];


                if(update && this.openLineSeries){
                    this.openLineSeries.update({ value: last_day.open, time: this.chartData.prices[this.chartData.prices.length-1].time })
                } else {
                    this.openLineSeries = this.chart.addLineSeries({ 
                        color: '#5d5d5e',
                        priceLineVisible: false,
                        lineWidth: 1,
                        lastValueVisible: false,
                        lineStyle: 3
                    });

                    this.openLineSeries.setData([
                        { value: last_day.open ,  time: this.chart[0].time }, 
                        { value: last_day.open, time: this.chartData.prices[this.chartData.prices.length-1].time }]
                    );
                }

                if(update && this.closeLineSeries){
                    this.closeLineSeries.update({ value: last_day.close, time: this.chartData.prices[this.chartData.prices.length-1].time })
                } else {

                    this.closeLineSeries = this.chart.addLineSeries({ 
                        color: '#5d5d5e',
                        priceLineVisible: false,
                        lineWidth: 1,
                        lastValueVisible: false,
                        lineStyle: 3
                    });
                    
                    this.closeLineSeries.setData([
                        { value: last_day.close ,  time: this.chartData.prices[0].time }, 
                        { value: last_day.close, time: this.chartData.prices[this.chartData.prices.length-1].time }]
                    );
                }


                if(update && this.highLineSeries){
                    this.highLineSeries.update({ value: last_day.high, time: this.chartData.prices[this.chartData.prices.length-1].time })
                } else {
                    this.highLineSeries = this.chart.addLineSeries({ 
                        color: '#5d5d5e',
                        priceLineVisible: false,
                        lineWidth: 1,
                        lastValueVisible: false,
                        lineStyle: 3
                    });
                    this.highLineSeries.setData([
                        { value: last_day.high ,  time: this.chartData.prices[0].time }, 
                        { value: last_day.high, time: this.chartData.prices[this.chartData.prices.length-1].time }]
                    );
                }

                if(update && this.lowLineSeries){
                    this.lowLineSeries.update({ value: last_day.low, time: this.chartData.prices[this.chartData.prices.length-1].time })
                } else {
                    this.lowLineSeries = this.chart.addLineSeries({ 
                        color: '#5d5d5e',
                        priceLineVisible: false,
                        lineWidth: 1,
                        lastValueVisible: false,
                        lineStyle: 3
                    });
                    
                    this.lowLineSeries.setData([
                        { value: last_day.low ,  time: this.chartData.prices[0].time }, 
                        { value: last_day.low, time: this.chartData.prices[this.chartData.prices.length-1].time }]
                    );
                }
            }
        },

        watch: {
            
            // "chartData.prices": {
            //     // This will let Vue know to look inside the array
            //     deep: true,

            //     // We have to move our method to a handler field
            //     handler(){
            //         if(!this.isLoaded) return;
                    
            //         this.addCandleSticksSeries(true);
            //         this.addVolumeSeries(true);
            //         // this.ticker = this.activeTicker;
            //         // this.timeframe = this.selectedTimeframe;
            //         // this.date = this.chartDate;
            //     }

            // }, 
            // isLoaded(){ 
            //     this.shouldSetDetails();
            // },
 
            // enabled_indicators(){
            //     this.shouldSetDetails();
            // },
            lines: {
                // This will let Vue know to look inside the array
                deep: true,

                // We have to move our method to a handler field
                handler(){
                    this.shouldSetDetails();
                }
            }, 
            chartData: {
                // This will let Vue know to look inside the array
                deep: true,

                // We have to move our method to a handler field
                handler(){
                    if(!this.isLoaded) return;
                    
                    this.createChart();
                    this.ticker = this.activeTicker;
                    this.timeframe = this.selectedTimeframe;
                    this.date = this.chartDate;
                }

            }, 

        }
    }
</script>

<style scoped>
    .chart-container{
        position: relative;
        width: 100%;
    }
</style>