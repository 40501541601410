<template>
    <v-card elevation="0" class="mt-1">
        
        <div v-show="isLoaded && !isResizing" :id="`chart-${id || activeTicker}`" style="height: 360px; max-width: 100%"></div>
        
        <div v-if="!isLoaded" class="pa-10">
            <p class="text-body-2 text-center">Retreiving Data</p>
            <v-progress-linear
                color="accent"
                indeterminate
                rounded
                height="6"
            ></v-progress-linear>
        
        </div>
  </v-card>
</template>


<script>
  import { mapGetters, mapMutations } from 'vuex';
  import { EventBus } from '../../modules/EventBus';
// import { EventBus } from '../../../../trader_web/src/modules/EventBus';
    export default {
        name: 'ChartIndex',
        data: () => ({
            enabled_indicators:['emas', 'hloc', 'smas' ],
            isResizing: false,
            tick_data: [],
            trade_data: {},

            chart: {},
            chart_options: {
                chart: {
                   marginRight: 50,
                   animation: false,
                //    width: 100, 
                   
                }, 
                 plotOptions: {
                     states: {
                        // hover: {
                        //     enabled: false
                        // },
                        inactive: {
                            opacity: 1,
                        }
                    }, 
                    series: {
                        animation: false,
                        states: {
                        // hover: {
                        //     enabled: false
                        // },
                            inactive: {
                                opacity: 1,
                            }
                        }, 
                        // enableMouseTracking: false,
                        // label: false
                    }, 
                    candlestick: {
                        allowPointSelect: false,
                        color: '#e74c3c', 
                        upColor: '#2ecc71',
                        pointPadding: 0,
                        groupPadding: .2,
                        
                        stickyTracking: false
                    },
                    
                    
                }, 
                tooltip: {
                    shared: true, 
                    stickyTracking: false,
                    enabled: false
                        
                },
                credits: {
                    enabled: false
                }, 
                exporting: {
                    enabled: false
                }, 
                scrollbar: { enabled: false },
                rangeSelector: {
                    enabled: false
                },
                navigator: {
                    enabled: false
                },
                title: {
                    text: ''
                },
                yAxis: {
                    crosshair: {
                        label: {
                            enabled: true
                        }
                    },
                    labels:{
                        align: 'left'
                    }, 
                    plotLines: [],
                },
                xAxis: {
                    
                    plotLines: [],
                    labels:{}
                },
                series: []
            },
            time_increments: {
                minute: 1000 * 60 * 5,
                hour: 1000 * 60 * 60,
                day: 1000 * 60 * 60 * 24
            } 
            
        }),

        components:{},
        props: ['lines', 'selectedTimeframe',  'dailyChart', 'intradayTrendsChart', 'intradayChart', 'activeTicker', 'isLoaded', 'id'], 

        async mounted(){ 
            
         },
        async created(){ 
            EventBus.$on('reflow', this.reflowChart)
            EventBus.$on('isResizing', this.setResizing)
        },
        async destroyed(){ 
            EventBus.$off('reflow', this.reflowChart)
            EventBus.$off('isResizing', this.setResizing)
            
        },
        computed: {
            ...mapGetters({
            }), 

            formatted_daily_data(){
                let ticks = JSON.parse(JSON.stringify(this.dailyChart.tick_data)); 

                if(this.lines.indexOf('ha_candles') >=0 ){
                    ticks = this.makeHACandles(ticks); 
                }
                return this.dailyChart.tick_data.filter(d => d.time >= this.intradayChart.ticks[0].time).map(td => {
                    return [parseFloat(td.time), parseFloat(td.open), parseFloat(td.high), parseFloat(td.low), parseFloat(td.close)]
                })
            }, 


            formated_data(){
                if(!this.intradayChart) return;

                let ticks = JSON.parse(JSON.stringify(this.intradayChart.ticks)); 

                if(this.lines.indexOf('ha_candles') >=0 ){
                    ticks = this.makeHACandles(ticks); 
                }

                let mapped_ticks =  ticks.map(td => {
                    return [parseFloat(td.time), parseFloat(td.open), parseFloat(td.high), parseFloat(td.low), parseFloat(td.close)]
                })

                // return mapped_ticks;

                let last_time = ticks.slice(-1)[0].time; 

        
                let formatted =  Array.from({...mapped_ticks,length:78}, (v,i) =>   v ?? [last_time + (i * this.time_increments[this.selectedTimeframe]),mapped_ticks[0][1], mapped_ticks[0][1], mapped_ticks[0][1], mapped_ticks[0][1]] ) 
                
                return formatted
            },
            placeholder(){
               
                let source_data = this.intradayChart.ticks || [];
                if(!source_data.length) return [];
                let starting_time = source_data[0].time;
                
                return [
                    [starting_time, source_data[0].open],
                    [starting_time * (78 * this.time_increments[this.selectedTimeframe])]
                ]

                // return Array.from({...[],length:78}, (v,i) =>   v ?? [ starting_time += (i * this.time_increments[this.selectedTimeframe]), source_data[0].open ] )         
            }
        }, 
        methods: {
            makeHACandles(data){ 
                let haCandles = data.map((d, i, arr) => {
                    d.close = (d.open + d.close + d.high + d.close) * .25
                    if(i >= 1){
                        d.open = (arr[i-1].open + arr[i-1].close) * .5
                    } else { 
                        d.open = d.open; 
                    }
                    d.high = Math.max(d.open, d.close, d.high)
                    d.low = Math.min(d.open, d.close, d.low)
                    return d;
                });

                return haCandles;
            },
            reflowChart(){
          //       this.chart.reflow();
                
            }, 
            setResizing(isResizing){
                //  this.isResizing = isResizing;
                // if(!this.isResizing){
                //     this.$nextTick(() => {
                //         this.chart.reflow()
                //     })
                // }
            }, 
            toggleIndicators(indicator_type){
                let index = this.enabled_indicators.findIndex(i => i === indicator_type);
                if(index >= 0){
                    this.enabled_indicators.splice(index, 1);
                } else {
                    this.enabled_indicators.push(indicator_type)
                }
            }, 
            isIndicatorActive(indicator_type){
                return this.enabled_indicators.find(i => i === indicator_type);
            },
           shouldSetDetails(){
                if(this.isLoaded){
                    this.createChart();
                }
            },
            createChart(){


                try{
                    this.chart_options.series = [];
                    this.chart.destroy();
                } catch(err){
                    
                }
                let _t = this;
                this.chart_options.xAxis.labels.formatter = function () {
                    const dateTimeStr = new Date(this.value).toLocaleString()
                    const result = (dateTimeStr.split(", ")[1]).split(':');
                    return result[0] + ":" + result[1];
                };

              


                this.chart_options.series.push({
                    id: 'primary_stock', 
                    type: 'candlestick',
                    allowPointSelect: false,
                    stickyTracking: false,
                    hover: {
                        enabled: false
                    },
                    point: {
                        events: {
                            click: function() {
                                console.log("here!")
                            }
                        }
                    },

                    name: this.activeTicker + ' Stock Price',
                    data: this.formated_data
                });
    




                // this.chart_options.series.push({
                //     id: 'placeholder', 
                //     type: 'line',
                //     type: 'line',
                //     color: '#2a9d8f', 
                //     fillColor: '#323232',
                //     data: this.placeholder
                // });



                this.addMinimasMaximas();
                if(this.lines.indexOf('consolidation') >= 0){
                    this.addConsolidationLines();
                }
                
                if(this.lines.indexOf('horizontal_support_daily') >= 0){
                    this.addHorizontalSupport();
                 }
                if(this.lines.indexOf('intraday_horizontal_support') >= 0 && this.selectedTimeframe !== 'day'){
                    this.addIntradayHorizontalSupport();
                }

                if(this.lines.indexOf('trendlines') >= 0){
                    this.addTrendLines();
                }
                if(this.lines.indexOf('intraday_trendlines') >= 0 && this.selectedTimeframe !== 'day'){
                    this.addIntradayTrendLines();
                }
                if(this.lines.indexOf('smas') >= 0){
                    this.addSMAs();
                }

                if(this.lines.indexOf('hloc' && this.selectedTimeframe !== 'day') >= 0){
                    this.addHLOC();
                }

                if(this.lines.indexOf('emas') >= 0 && this.selectedTimeframe !== 'day'){
                    this.addEMAs();
                }

                this.chart = Highcharts.stockChart(`chart-${this.id || this.activeTicker}`, this.chart_options); 
               if(this.chart_options.series[0].data){
                    this.chart.yAxis[0].setExtremes(Math.min(...this.chart_options.series[0].data.map(dp => dp[3] * .99)), Math.max(...this.chart_options.series[0].data.map(dp => dp[2] * 1.01)) )
                    // this.chart.xAxis[0].setExtremes(this.chart_options.series[0].data[0][0], this.chart_options.series[0].data[0][0] + (78 * time_increments[this.selectedTimeframe] ) )
                    // this.chart.xAxis[0].minRange = (78 * time_increments[this.selectedTimeframe]);
                    // this.chart_options.xAxis.setExtremes(this.chart_options.series[0].data[0][0], this.chart_options.series[0].data[0][0] + (78 * time_increments[this.selectedTimeframe] ) )
                }
                
            },


            addHorizontalSupport(){
               
                    if(this.dailyChart.horizontal_support.maximas){
                        for(let i = 0; i < this.dailyChart.horizontal_support.maximas.length; i++){

                             if(this.selectedTimeframe === 'day'){

                                this.chart_options.series.push({
                                    type: 'line',
                                    color: '#2a9d8f', 
                                    fillColor: '#323232',
                                    title: 'Horizontal Support',
                                    name: "Horizontal Support",
                                      allowPointSelect: false,
                                    stickyTracking: false,
                                    hover: {
                                        enabled: false
                                    },
                                    text: null,
                                    data: [
                                        [this.dailyChart.horizontal_support.maximas[i].time, this.dailyChart.horizontal_support.maximas[i].price],
                                        [this.formatted_daily_data[this.formatted_daily_data.length - 1][0], this.dailyChart.horizontal_support.maximas[i].price ],
                                    ]
                                });
                             } else {
                                 this.chart_options.series.push({
                                    type: 'line',
                                    color: '#2a9d8f', 
                                    fillColor: '#323232',
                                    shadow: true,
                                    title: 'Horizontal Support',
                                    name: "Horizontal Support", 
                                    text: 'Horizontal Support',
                                    
                                    allowPointSelect: false,
                                    stickyTracking: false,
                                    hover: {
                                        enabled: false
                                    },
                                    marker: {
                                        enabled: true,
                                        symbol: 'circle',
                                        states: {
                                            hover: {
                                                enabled: true
                                            }
                                        }
                                    }, 
                                    data: [
                                        [this.formated_data[0][0], this.dailyChart.horizontal_support.maximas[i].price],
                                        [this.formated_data[this.formated_data.length-1][0], this.dailyChart.horizontal_support.maximas[i].price ],
                                    ]
                                });
                             }
                        }
                    }   
                    if(this.dailyChart.horizontal_support.minimas){
                        for(let i = 0; i < this.dailyChart.horizontal_support.minimas.length; i++){
                             if(this.selectedTimeframe === 'day'){
                                this.chart_options.series.push({
                                    type: 'line',
                                    color: '#2a9d8f', 
                                    fillColor: '#323232',
                                    title: 'Horizontal Support',
                                    text: null,
                                    data: [
                                        [this.dailyChart.horizontal_support.minimas[i].time, this.dailyChart.horizontal_support.minimas[i].price],
                                        [this.formatted_daily_data[this.formatted_daily_data.length - 1][0], this.dailyChart.horizontal_support.minimas[i].price ],
                                    ]
                                });
                            } else {
                                 this.chart_options.series.push({
                                    type: 'line',
                                    color: '#2a9d8f', 
                                    fillColor: '#323232',
                                    title: 'Horizontal Support',
                                    text: null,
                                    data: [
                                        [this.formated_data[0][0], this.dailyChart.horizontal_support.minimas[i].price],
                                        [this.formated_data[this.formated_data.length-1][0], this.dailyChart.horizontal_support.minimas[i].price ],
                                    ]
                                });
                             }
                        }   
                    }
            },

            addMinimasMaximas(){

                if(this.intradayTrendsChart.maximas){

                    this.chart_options.series.push({
                        marker: {
                            enabled: true,
                            radius: 2
                        },
                        color: '#2a9d8f', 
                        fillColor: '#323232',
                        title: 'Maxima',
                        text: '',
                        lineWidth: 0,
                        states: {
                            hover: {
                                lineWidthPlus: 0
                            }
                        },
                        data: this.intradayTrendsChart.maximas.map(m => [m.time, m.price])
                    });   
                }
                if(this.intradayTrendsChart.minimas){
                    this.chart_options.series.push({
                        
                        marker: {
                            enabled: true,
                            radius: 2
                        },
                        color: '#2a9d8f', 
                        fillColor: '#323232',
                        title: 'Maxima',
                        text: '',
                        lineWidth: 0,  
                        states: {
                            hover: {
                                lineWidthPlus: 0
                            }
                        },
                        data: this.intradayTrendsChart.minimas.map(m => [m.time, m.price])
                    }); 
                }
        
            }, 

            addConsolidationLines(){
                if(this.intradayTrendsChart?.consolidation?.maximaConsoidationLine?.start){

                    this.chart_options.series.push({
                        type: 'line',
                        color: this.intradayTrendsChart.consolidation.consolidationPattern === "Breakout" ? '#00a8ff': '#a0a0a0',
                        title: 'Consolidation Line',
                        data: [
                            [this.intradayTrendsChart.consolidation.maximaConsoidationLine.start.time, this.intradayTrendsChart.consolidation.maximaConsoidationLine.start.price],
                            [this.formated_data[this.formated_data.length - 1][0], (this.intradayTrendsChart.consolidation.maximaConsoidationLine.m * this.formated_data.length) + this.intradayTrendsChart.consolidation.maximaConsoidationLine.b ],
                        ]
                    });  
                }
                
                if(this.intradayTrendsChart?.consolidation?.minimaConsoidationLine?.start){
                    this.chart_options.series.push({
                        type: 'line',
                        color: this.intradayTrendsChart.consolidation.consolidationPattern === "Breakdown" ? '#00a8ff': '#a0a0a0',
                        title: 'Consolidation Line',    
                        data: [
                            [this.intradayTrendsChart.consolidation.minimaConsoidationLine.start.time, this.intradayTrendsChart.consolidation.minimaConsoidationLine.start.price],
                            [this.formated_data[this.formated_data.length - 1][0], (this.intradayTrendsChart.consolidation.minimaConsoidationLine.m * this.formated_data.length) + this.intradayTrendsChart.consolidation.minimaConsoidationLine.b ],
                        ]
                    });
                }  
                
                if(this.intradayTrendsChart?.consolidation?.formation === "Wedge"){
                    let range_data = this.formated_data.map(d => [d[0], this.intradayTrendsChart.consolidation.minimaConsoidationLine.end.price, this.intradayTrendsChart.consolidation.maximaConsoidationLine.end.price ])
                    this.chart_options.series.push({
                        type: 'arearange',
                        title: 'Consolidation Line',
                        fillOpacity: .25,
                        color: '#00a8ff',
                        data: range_data
                    });  
                }



            }, 


            addIntradayHorizontalSupport(){

                for(let i = 0; i < this.intradayTrendsChart.horizontal_support.maximas.length; i++){
                    
                    this.chart_options.series.push({
                            type: 'line',
                            color: '#2a9d8f', 
                            fillColor: '#323232',
                            title: 'Trend Line',
                            text: '',
                            data: [
                                    [this.intradayTrendsChart.horizontal_support.maximas[i].time, this.intradayTrendsChart.horizontal_support.maximas[i].price],
                                    [this.formated_data[this.formated_data.length - 1][0], this.intradayTrendsChart.horizontal_support.maximas[i].price ],
                                ]
                            });   
                }
                 
                for(let i = 0; i < this.intradayTrendsChart.horizontal_support.minimas.length; i++){
                    
                    this.chart_options.series.push({
                        type: 'line',
                        color: '#2a9d8f', 
                        fillColor: '#323232', 
                        title: 'Trend Line',
                        text: ``,
                        data: [
                             [this.intradayTrendsChart.horizontal_support.minimas[i].time, this.intradayTrendsChart.horizontal_support.minimas[i].price],
                            [this.formated_data[this.formated_data.length - 1][0], this.intradayTrendsChart.horizontal_support.minimas[i].price ],
                        ]
                    });      
                }

            }, 

            addIntradayTrendLines(){
                

                for(let i = 0; i < this.intradayTrendsChart.trends.maximas.length; i++){
                  
                    this.chart_options.series.push({
                            type: 'line',
                            color: '#2a9d8f', 
                            fillColor: '#323232',
                            title: 'Trend Line',
                            text: `${this.intradayTrendsChart.trends.maximas[i].points.length}, ${this.intradayTrendsChart.trends.maximas[i].magnitude.toFixed(2)}, ${this.intradayTrendsChart.trends.maximas[i].strength}`,
                            data: [
                                    [this.intradayTrendsChart.trends.maximas[i].start.time, this.intradayTrendsChart.trends.maximas[i].start.price],
                                    [this.formated_data[this.formated_data.length - 1][0], (this.intradayTrendsChart.trends.maximas[i].m * this.formated_data.length) + this.intradayTrendsChart.trends.maximas[i].b ],
                                ]
                            });   
                }
                 
                for(let i = 0; i < this.intradayTrendsChart.trends.minimas.length; i++){
                    this.chart_options.series.push({
                        type: 'line',
                        color: '#2a9d8f', 
                        fillColor: '#323232', 
                        title: 'Trend Line',
                        text: `${this.intradayTrendsChart.trends.minimas[i].points.length}, ${this.intradayTrendsChart.trends.minimas[i].magnitude.toFixed(2)}, ${this.intradayTrendsChart.trends.minimas[i].strength}`,
                        data: [
                            [this.intradayTrendsChart.trends.minimas[i].start.time, this.intradayTrendsChart.trends.minimas[i].start.price],
                            [this.formated_data[this.formated_data.length - 1][0], (this.intradayTrendsChart.trends.minimas[i].m * (this.formated_data.length - 1)) + this.intradayTrendsChart.trends.minimas[i].b ],,
                        ]
                    });      
                }
            }, 
            addTrendLines(){


                if(this.dailyChart.trends.maximas){
                    // let maximas = this.dailyChart.trends.maximas.filter(d => d.start.time >= this.intradayChart.ticks[0].time)    
                    for(let i = 0; i < this.dailyChart.trends.maximas.length; i++){
                        if(this.selectedTimeframe === 'day'){
                            this.chart_options.series.push({
                            type: 'line',
                            color: '#2a9d8f', 
                            fillColor: '#323232',
                            title: 'Trend Line',
                            text: `${this.dailyChart.trends.maximas[i].points.length}, ${this.dailyChart.trends.maximas[i].magnitude.toFixed(2)}, ${this.dailyChart.trends.maximas[i].strength}`,
                            data: [
                                    [this.dailyChart.trends.maximas[i].start.time, this.dailyChart.trends.maximas[i].start.price],
                                    [this.formatted_daily_data[this.formatted_daily_data.length - 1][0], (this.dailyChart.trends.maximas[i].m * this.formatted_daily_data.length) + this.dailyChart.trends.maximas[i].b ],
                                ]
                            });
                        } else {
                            this.chart_options.series.push({
                                type: 'line',
                                color: '#2a9d8f', 
                                fillColor: '#323232',
                                title: 'Trend Line',
                                text: 'Trend Line',
                                data: [
                                    [this.formated_data[0][0], (this.dailyChart.trends.maximas[i].m * this.formated_data.length) + this.dailyChart.trends.maximas[i].b ], 
                                    [this.formated_data[this.formated_data.length-1][0], (this.dailyChart.trends.maximas[i].m * this.formated_data.length) + this.dailyChart.trends.maximas[i].b] ],
                            })

                        }   
                    }
                }   
                
                if(this.dailyChart.trends.minimas){
                    //let minimas = this.dailyChart.trends.minimas.filter(d => d.start.time >= this.intradayChart.ticks[0].time)    
                    
                    for(let i = 0; i < this.dailyChart.trends.minimas.length; i++){
                         
                        if(this.selectedTimeframe === 'day'){

                             this.chart_options.series.push({
                                type: 'line',
                                color: '#2a9d8f', 
                                fillColor: '#323232', 
                                title: 'Trend Line',
                                text: `${this.dailyChart.trends.minimas[i].points.length}, ${this.dailyChart.trends.minimas[i].magnitude.toFixed(2)}, ${this.dailyChart.trends.minimas[i].strength}`,
                                data: [
                                    [this.dailyChart.trends.minimas[i].start.time, this.dailyChart.trends.minimas[i].start.price],
                                    [this.formatted_daily_data[this.formatted_daily_data.length - 1][0], (this.dailyChart.trends.minimas[i].m * (this.formatted_daily_data.length - 1)) + this.dailyChart.trends.minimas[i].b ],,
                                ]
                            });
    
                         } else {
                            this.chart_options.series.push({
                                type: 'line',
                                color: '#2a9d8f', 
                                fillColor: '#323232', 
                                title: 'Trend Line',
                                itle: 'Trend Line',
                                data: [
                                    [this.formated_data[0][0], (this.dailyChart.trends.minimas[i].m * this.formated_data.length) + this.dailyChart.trends.minimas[i].b ], 
                                    [this.formated_data[this.formated_data.length-1][0], (this.dailyChart.trends.minimas[i].m * this.formated_data.length) + this.dailyChart.trends.minimas[i].b] 
                                ],
                            });
                        }
                    }
                }   

            }, 
            addSMAs(){

                if(this.selectedTimeframe === 'day'){
                    this.chart_options.series.push({
                        id: 'SMA50', 
                        type: 'line',
                        name: this.activeTicker + ' SMA 50',
                        data: this.dailyChart.sma50.map(sma => [sma.time, sma.value]),
                        color: '#f1c40f',
                    })

                    this.chart_options.series.push({
                        id: 'SMA100', 
                        type: 'line',
                        name: this.activeTicker + ' SMA 100',
                        data: this.dailyChart.sma100.map(sma => [sma.time, sma.value]),
                        color: '#e67e22',
                    })

                    this.chart_options.series.push({
                        id: 'SMA200', 
                        type: 'line',
                        name: this.activeTicker + ' SMA 200',
                        data: this.dailyChart.sma200.map(sma => [sma.time, sma.value]),
                        color: '#e74c3c',
                    })

                } else {

                    this.chart_options.series.push({
                        id: 'SMA50', 
                        type: 'line',
                        name: this.activeTicker + ' SMA 50',
                        data: [[this.formated_data[0][0], this.dailyChart.sma50[this.dailyChart.sma50.length - 1].value], [this.formated_data[this.formated_data.length-1][0], this.dailyChart.sma50[this.dailyChart.sma50.length - 1].value] ],
                        color: '#f1c40f',
                    })

                     this.chart_options.series.push({
                        id: 'SMA100', 
                        type: 'line',
                        name: this.activeTicker + ' SMA 100',
                        data: [[this.formated_data[0][0], this.dailyChart.sma100[this.dailyChart.sma100.length - 1].value], [this.formated_data[this.formated_data.length-1][0], this.dailyChart.sma100[this.dailyChart.sma100.length - 1].value] ],
                        color: '#e67e22',
                    })

                    //  this.chart_options.series.push({
                    //     id: 'SMA200', 
                    //     type: 'line',
                    //     name: this.activeTicker + ' SMA 200',
                    //     data: [[this.formated_data[0][0], this.dailyChart.sma200[this.dailyChart.sma200.length - 1].value], [this.formated_data[this.formated_data.length-1][0], this.dailyChart.sma200[this.dailyChart.sma200.length - 1].value] ],
                    //     color: '#e74c3c',
                    // })
                }
            
                // this.chart_options.series.push({
                //     id: 'SMA100', 
                //     type: 'line',
                //     name: this.activeTicker + ' SMA 100',
                //     data: [[this.formated_data[0][0], this.formatted_daily_data.sma100[this.formatted_daily_data.sma100.length - 1].value], [this.formated_data[this.formated_data.length-1][0], this.formatted_daily_data.sma100[this.formatted_daily_data.sma100.length - 1].value ] ],
                //     color: '#e67e22',
                // })
                // this.chart_options.series.push({
                //     id: 'SMA200', 
                //     type: 'line',
                //     name: this.activeTicker + ' SMA 200',
                //     data: [[this.formated_data[0][0], this.formatted_daily_data.sma200[this.formatted_daily_data.sma200.length - 1].value], [this.formated_data[this.formated_data.length-1][0], this.formatted_daily_data.sma200[this.formatted_daily_data.sma200.length - 1].value] ],
                //     color: '#e74c3c',
                // })
            }, 
            addEMAs(){

                this.chart_options.series.push({
                        id: 'ema3', 
                        type: 'line',
                        name: this.activeTicker + ' EMA3',
                        data: this.intradayChart.ema3,
                        color: '#70a1ff',
                    })

                    this.chart_options.series.push({
                        id: 'ema8', 
                        type: 'line',
                        name: this.activeTicker + ' EMA8',
                        data: this.intradayChart.ema8,
                        color: '#1e90ff',
                    });

                    this.chart_options.series.push({
                        id: 'ema21', 
                        type: 'line',
                        name: this.activeTicker + ' EMA21',
                        data: this.intradayChart.ema21,
                        color: '#3742fa',
                    });

                     this.chart_options.series.push({
                        id: 'vwap', 
                        type: 'line',
                        name: this.activeTicker + ' VWAP',
                        data: this.intradayChart.VWAP,
                        color: '#8e44ad',
                    });


            },

            addHLOC(){
                    let last_day = this.dailyChart.tick_data[this.dailyChart.tick_data.length - 2];
                    this.chart_options.series.push({
                        id: 'open', 
                        type: 'line',
                        name: this.activeTicker + ' open',
                        data: [[this.formated_data[0][0], last_day.open], [this.formated_data[this.formated_data.length-1][0], last_day.open] ],
                        'stroke-width': .25,
                        color: '#40739e',
                        dashStyle: 'dash'
                    });

                    this.chart_options.series.push({
                        id: 'last_close', 
                        type: 'line',
                        name: this.activeTicker + ' last_close',
                        data: [[this.formated_data[0][0], last_day.close], [this.formated_data[this.formated_data.length-1][0], last_day.close] ],
                        'stroke-width': .25,
                        color: '#40739e',
                        dashStyle: 'dash'
                    })

                    this.chart_options.series.push({
                        id: 'last_high', 
                        type: 'line',
                        name: this.activeTicker + ' last_high',
                        data: [[this.formated_data[0][0], last_day.high], [this.formated_data[this.formated_data.length-1][0], last_day.high] ],
                        'stroke-width': .25,
                        color: '#40739e',
                        
                        dashStyle: 'dash'
                    })

                    this.chart_options.series.push({
                        id: 'last_low', 
                        type: 'line',
                        name: this.activeTicker + ' last_low',
                        data: [[this.formated_data[0][0], last_day.low], [this.formated_data[this.formated_data.length-1][0], last_day.low] ],
                        'stroke-width': .25,
                        color: '#40739e',
                        dashStyle: 'dash'
                    })
            }
        },

        watch: {
            
            activeTicker(){  
                 this.shouldSetDetails();
            },
            isLoaded(){ 
                this.shouldSetDetails();
            },
            // "dailyChart.isLoaded"(isLoaded){     
            //     this.shouldSetDetails();
            // },
            enabled_indicators(){
                this.shouldSetDetails();
            },
            lines: {
                // This will let Vue know to look inside the array
                deep: true,

                // We have to move our method to a handler field
                handler(){
                    this.shouldSetDetails();
                }
            }, 
            intradayChart: {
                // This will let Vue know to look inside the array
                deep: true,

                // We have to move our method to a handler field
                handler(){
                    this.shouldSetDetails();
                }
            }, 
            selectedTimeframe(){
               this.shouldSetDetails();
            }
        }
    }
</script>

<style scoped>

</style>